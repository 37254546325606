import { Dataset } from 'fsxa-api'
import {
  Module, VuexModule, VuexAction, VuexMutation,
} from 'nuxt-property-decorator'
import fsxaProxyApiRemote from '../shared/fsxa/services/FsxaProxyApiRemote'

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class Locations extends VuexModule {
  savedLocations : Record<string, string> = {}

  campaignLocations : Record<string, string> = {}

  campaignLocationsSet : boolean = false

  @VuexAction
  async setCampaignLocationId ({ locationId, locale } : { locationId : string, locale : string }) : Promise<void> {
    if (!locationId || !locale) {
      // this has to be called even if there is nothing to save so campaignLocationsSet gets set to true
      this.context.commit('setCampaignLocations', {})
      return
    }

    let locationTypes = {}

    try {
      const location = await fsxaProxyApiRemote.fetchElement({
        id: locationId,
        locale,
      })

      if (!location) {
        this.context.commit('setCampaignLocations', {})
        return
      }

      locationTypes = (location.data?.pt_location?.data?.tt_location_types || location.data?.tt_location_types)
        ?.reduce((types : Record<string, string>, type : Dataset) => ({ ...types, [type.data.tt_key]: locationId }), {})
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(`Unable to fetch location with GID: ${locationId}`)
    }

    // this has to be called even if there is nothing to save so campaignLocationsSet gets set to true
    this.context.commit('setCampaignLocations', locationTypes || {})
  }

@VuexAction
  async getSavedLocationId (locationType : string | undefined) : Promise<string> {
    if (!locationType) return ''

    /**
     * we have to wait for the campaign stuff to be set before we can return anything here and waiting in the mounted/created section of the AppLayout
     * doesn't work since child components don't wait for some reason
     */
    if (!this.campaignLocationsSet) {
      await new Promise((resolve) => {
        window.addEventListener('campaignUrlSet', resolve)
      })
    }

    // this logic is very important to components relying on this. If you ever make a change to this be very careful
    const campaignLocationId : string = this.campaignLocations[locationType]
    if (campaignLocationId) return campaignLocationId

    try {
      const savedLocations : Record<string, string> = JSON.parse(localStorage.getItem('savedLocations') || '{}')
      return savedLocations[locationType] || ''
    } catch (e) {
      return ''
    }
  }

@VuexMutation
set (savedLocations : Record<string, string>) : void {
  this.savedLocations = savedLocations
}

  @VuexMutation
setCampaignLocations (campaignLocations : Record<string, string>) : void {
  this.campaignLocations = campaignLocations
  this.campaignLocationsSet = true
  window.dispatchEvent(new CustomEvent('campaignUrlSet'))
}

@VuexMutation
  resetCampaignLocationId () : void {
    this.campaignLocations = {}
  }

  @VuexAction
saveLocation ({ locationId, locationType } : {locationId : string, locationType : string}) : boolean {
  if (!locationId || !locationType) return false

  try {
    const savedLocations : Record<string, string> = JSON.parse(localStorage.getItem('savedLocations') || '{}')
    savedLocations[locationType] = locationId
    localStorage.setItem('savedLocations', JSON.stringify(savedLocations))
    this.context.commit('set', savedLocations)
    return true
  } catch (e) {
    return false
  }
}

  @VuexAction
  isLocationIdSaved (locationId : string | undefined) : boolean {
    if (!locationId) return false

    try {
      const savedLocations : Record<string, string> = JSON.parse(localStorage.getItem('savedLocations') || '{}')
      return Object.values(savedLocations).includes(locationId)
    } catch (e) {
      return false
    }
  }

  @VuexAction
  getSavedLocationTypes () : string[] {
    try {
      const savedLocations : Record<string, string> = JSON.parse(localStorage.getItem('savedLocations') || '{}')
      this.context.commit('set', savedLocations)
      return Object.keys(savedLocations) || []
    } catch (e) {
      return []
    }
  }

  @VuexAction
  deleteLocation (locationType : string) : boolean {
    try {
      const savedLocations : Record<string, string> = JSON.parse(localStorage.getItem('savedLocations') || '{}')
      delete savedLocations[locationType]
      localStorage.setItem('savedLocations', JSON.stringify(savedLocations))
      this.context.commit('set', savedLocations)
      return true
    } catch (e) {
      return false
    }
  }
}
