import {
  Module, VuexAction, VuexModule, VuexMutation,
} from 'nuxt-property-decorator'
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader'

enum EInitializationState {
  NotInitialized = 'notInitialized',
  InProgress = 'inProgress',
  Initialized = 'initialized',
}

@Module({
  namespaced: true,
  stateFactory: true,
})
export default class GoogleMaps extends VuexModule {
  initializationState : EInitializationState = EInitializationState.NotInitialized

  google ?: typeof google

  @VuexAction
  async init (options : LoaderOptions) : Promise<boolean> {
    const context = this.context as any

    /*
     * there are three possible cases down there
     * 1: we have to load the google maps object
     * 2: it's being loaded somewhere already, so we have to wait
     * 3: it's loaded and we're ready to go
    */
    return new Promise((resolve) => {
      if (context.state.initializationState === EInitializationState.InProgress) {
        // (2) check if it is being initialized in another component at the moment, if so wait for that one to finish
        const interval = setInterval(() => {
          if (context.state.initializationState === EInitializationState.Initialized) {
            clearInterval(interval)
            resolve(true)
          }
        }, 50)
      } else if (context.state.initializationState === EInitializationState.NotInitialized) {
        // (1) check if google is already loaded, if not -> load
        context.commit('setInitializing')

        try {
          const googleMapsLoader = new Loader(options);

          // async wrapper to be able to await inside the promise
          (async () => {
            const google = await googleMapsLoader.load()
            context.commit('setGoogle', google)
            context.commit('setInitialized')
            resolve(true)
          })()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
          resolve(false)
        }
      } else {
        // (3) everything is loaded, just return
        resolve(true)
      }
    })
  }

@VuexMutation
  setGoogle (google : any) {
    this.google = google
  }

  @VuexMutation
setInitialized () {
  this.initializationState = EInitializationState.Initialized
}

  @VuexMutation
  setInitializing () {
    this.initializationState = EInitializationState.InProgress
  }
}
